import styles from "./Events.module.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const MyEvents = () => {
  const [events, setEvents] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_BASE_URL + "/events/myEvents", {
        headers: {
          Authorization: `${localStorage.getItem("userToken")}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setEvents(data.event || []);
      } else {
        console.error("Error fetching events:", response.statusText);
        setEvents([]);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      setEvents([]);
    }
  };

  const handleGiveFeedback = (event) => {
    setSelectedEvent(event);
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setSelectedEvent(null);
    setShowForm(false);
  };

  const handleSubmitFeedback = async (e) => {
    e.preventDefault();
    const formData = {
      title: e.target.title.value,
      rating: parseInt(e.target.rating.value),
      comment: e.target.comment.value,
    };


    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_BASE_URL + `/fb/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${localStorage.getItem("userToken")}`,
        },
        body: JSON.stringify({ ...formData, event_id: selectedEvent.events_id }),
      });


      if (response.ok) {
        setSelectedEvent(null);
        setShowForm(false);
        fetchEvents();
        alert("Submitted feedback successfully!")
      } else {
        alert("Error submitting feedback:", response.statusText);
      }
    } catch (error) {
      alert("Error submitting feedback:", error);
    }
  };

  const filteredEvents = events.filter((event) =>
    event.event_name.toLowerCase().includes(searchQuery.toLowerCase())
  );


  return (
    <div className={styles.events}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <img src="illinois-fighting-illini-logo-1@2x.png" alt="Logo" />
        </div>
        <div className={styles.navList}>
          <div className={styles.sections}>
            <Link to="/home" className={styles.navLink}>
              Home
            </Link>
            <Link to="/events" className={styles.navLink}>
              Events
            </Link>
            <Link to="/organizations" className={styles.navLink}>
              Organizations
            </Link>
            <Link to="/venues" className={styles.navLink}>
              Venues
            </Link>
            <Link to="/myEvents" className={styles.navLink_active}>
              My Events
            </Link>
            <Link to="/visualization" className={styles.navLink}>Visualization</Link>

          </div>
        </div>
      </div>
      {/*<div className={styles.searchAndCreate}>
        <input
          type="text"
          placeholder="Search Events"
          className={styles.searchBar}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button className={styles.createButton} onClick={handleCreateEvent}>
          Create Event
        </button>
      </div>*/}
      <div className={styles.eventList}>
        {filteredEvents.map((event) => (
          <div key={event.event_id} className={styles.eventCard}>
            <h3>{event.event_name}</h3>
            <p>Start Date: {event.start_at}</p>
            <p>End Date: {event.end_at}</p>
            <p>Cost: ${event.cost}</p>
            <div className={styles.cardButtons}>
            </div>
            <button className={styles.feedbackButton} onClick={() => handleGiveFeedback(event)}>
              Give Feedback
            </button>
          </div>
        ))}
      </div>
      {/* Dummy event card */}
      {/*<div className={styles.eventCard}>
          <h3>Test Event</h3>
          <p>Start Date: 2023-06-10 10:00:00</p>
          <p>End Date: 2023-06-10 12:00:00</p>
          <p>Cost: $50</p>
          <div className={styles.cardButtons}>
            <button className={styles.updateButton}>
              <img src="/pencil.png" alt="Update" />
            </button>
            <button className={styles.joinButton}>Join</button>
            <button className={styles.deleteButton}>
              <img src="/trash.png" alt="Delete" />
            </button>
          </div>
        </div>*/}
      {showForm && (
        <div className={styles.formOverlay}>
          <div className={styles.formContainer}>
            <button className={styles.closeButton} onClick={handleCloseForm}>
              <img src="/close.png" alt="Close" />
            </button>
            <h2>Give Feedback</h2>
            <form onSubmit={handleSubmitFeedback}>
              <div className={styles.formField}>
                <label htmlFor="title">Title:</label>
                <input type="text" id="title" required />
              </div>
              <div className={styles.formField}>
                <label htmlFor="rating">Rating:</label>
                <input type="number" id="rating" min="1" max="5" required />
                {/* <label for="ratings">Rating</label>
                <select name="rating" placeholder="Rating" className={styles.dropDown}>
                  <option value="campus" className={styles.dropDown}>1</option>
                  <option value="campus" className={styles.dropDown}>2</option>
                  <option value="campus" className={styles.dropDown}>3</option>
                  <option value="campus" className={styles.dropDown}>4</option>
                  <option value="campus" className={styles.dropDown}>5</option>
                </select> */}
              </div>
              <div className={styles.formField}>
                <label htmlFor="comment">Comment:</label>
                <textarea id="comment" required></textarea>
              </div>
              <button type="submit" className={styles.submitButton}>Submit Feedback</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyEvents;