import React from "react";
import { VegaLite } from "react-vega";
import vegaLiteSpec from "./viz.json";
import styles from "./Events.module.css";
import { Link } from "react-router-dom";

const Visualization = () => {  
  return (
    <div className={styles.events}>
    <div className={styles.header}>
        <div className={styles.logo}>
          <img src="illinois-fighting-illini-logo-1@2x.png" alt="Logo" />
        </div>
        <div className={styles.navList}>
          <div className={styles.sections}>
            <Link to="/home" className={styles.navLink}>Home</Link>
            <Link to="/events" className={styles.navLink}>Events</Link>
            <Link to="/organizations" className={styles.navLink}>Organizations</Link>
            <Link to="/venues" className={styles.navLink}>Venues</Link>
            <Link to="/myEvents" className={styles.navLink}>My Events</Link>
            <Link to="/visualization" className={styles.navLink_active}>Visualization</Link>
          </div>
        </div>
      </div>
    <div>
      <h1>Event Visualization</h1>
      <VegaLite spec={vegaLiteSpec}/>
    </div>
    </div>
  );
};

export default Visualization;