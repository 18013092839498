import styles from "./Events.module.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const Events = () => {
  const [events, setEvents] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchEvents();
  }, []);

  // const fetchEvents = async (searchQuery = "") => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_BACKEND_BASE_URL}/events/getAll?searchQuery=${searchQuery}`,
  //       {
  //         headers: {
  //           Authorization: `${localStorage.getItem("userToken")}`,
  //         },
  //       }
  //     );
  //     if (response.ok) {
  //       const data = await response.json();
  //       setEvents(data.event || []);
  //     } else {
  //       console.error("Error fetching events:", response.statusText);
  //       setEvents([]);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching events:", error);
  //     setEvents([]);
  //   }
  // };
  const fetchEvents = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_BASE_URL + "/events/getAll",
        {
          headers: {
            Authorization: `${localStorage.getItem("userToken")}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setEvents(data.event || []);
      } else {
        console.error("Error fetching events:", response.statusText);
        setEvents([]);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
      setEvents([]);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    fetchEvents(e.target.value);
  };

  const handleCreateEvent = () => {
    setSelectedEvent(null);
    setShowForm(true);
  };

  const handleUpdateEvent = (event) => {
    setSelectedEvent(event);
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setSelectedEvent(null);
    setShowForm(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      event_name: e.target.eventName.value,
      start_at: e.target.startAt.value,
      organisation_id: parseInt(e.target.organisationId.value),
      venue_id: parseInt(e.target.venueId.value),
      cost: parseFloat(e.target.cost.value),
      description: e.target.description.value,
      end_at: e.target.endAt.value,
      max_participants: parseInt(e.target.maxParticipants.value),
      image: e.target.image.value,
      booking_date: e.target.bookingDate.value,
      booking_time: e.target.bookingDate.value,
    };

    try {
      let response;
      if (selectedEvent) {
        // Update event
        response = await fetch(
          process.env.REACT_APP_BACKEND_BASE_URL + `/events/update`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${localStorage.getItem("userToken")}`,
            },
            body: JSON.stringify({
              ...formData,
              events_id: selectedEvent.events_id,
            }),
          }
        );
      } else {
        // Create new event
        try {
          response = await fetch(
            process.env.REACT_APP_BACKEND_BASE_URL + `/events/create`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `${localStorage.getItem("userToken")}`,
              },
              body: JSON.stringify(formData),
            }
          );
          console.log(formData);
        } catch (e) {
          console.log(e);
        }
      }

      if (response.ok) {
        setSelectedEvent(null);
        setShowForm(false);
        fetchEvents();
      } else {
        console.error("Error submitting form:", response.statusText);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleJoinEvent = async (eventId) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_BASE_URL + `/events/join`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("userToken")}`,
          },
          body: JSON.stringify({ events_id: eventId }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        alert(
          `${data.message}\n\nVenue: ${data.event_venue_stats.venue_name}\nTotal Events: ${data.event_venue_stats.total_events}\nTotal Capacity: ${data.event_venue_stats.total_capacity}\nTotal Bookings: ${data.event_venue_stats.total_bookings}\nTotal Revenue: ${data.event_venue_stats.total_revenue}`
        );
        fetchEvents();
      } else {
        console.error("Error joining event:", response.statusText);
        // Display an error message to the user
        alert("Failed to join the event. Please try again later.");
      }
    } catch (error) {
      console.error("Error joining event:", error);
      // Display an error message to the user
      alert("You have already joined this event");
    }
  };

  const handleDeleteEvent = async (eventId) => {
    try {
      console.log(eventId);
      const response = await fetch(
        process.env.REACT_APP_BACKEND_BASE_URL +
          `/events/delete?events_id=` +
          eventId,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("userToken")}`,
          },
          body: JSON.stringify({ events_id: eventId }),
        }
      );

      if (response.ok) {
        fetchEvents();
        alert("Event delete successfully!");
      } else {
        alert("Error deleting event:", response.statusText);
      }
    } catch (error) {
      alert("Error deleting event:", error);
    }
  };

  const filteredEvents = events.filter((event) =>
    event.event_name.toLowerCase()
  );

  const handleSearch = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_BASE_URL + "/events/search",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("userToken")}`,
          },
          body: JSON.stringify({ searchQuery }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setEvents(data.event || []);
      } else {
        console.error("Error searching events:", response.statusText);
      }
    } catch (error) {
      console.error("Error searching events:", error);
    }
  };

  return (
    <div className={styles.events}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <img src="illinois-fighting-illini-logo-1@2x.png" alt="Logo" />
        </div>
        <div className={styles.navList}>
          <div className={styles.sections}>
            <Link to="/home" className={styles.navLink}>
              Home
            </Link>
            <Link to="/events" className={styles.navLink_active}>
              Events
            </Link>
            <Link to="/organizations" className={styles.navLink}>
              Organizations
            </Link>
            <Link to="/venues" className={styles.navLink}>
              Venues
            </Link>
            <Link to="/myEvents" className={styles.navLink}>
              My Events
            </Link>
            <Link to="/visualization" className={styles.navLink}>
              Visualization
            </Link>
          </div>
        </div>
      </div>
      {/* <div className={styles.searchAndCreate}>
        <input
          type="text"
          placeholder="Search Events"
          className={styles.searchBar}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <input
          type="text"
          placeholder="Search Events"
          className={styles.searchBar}
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <button className={styles.createButton} onClick={handleCreateEvent}>
          Create Event
        </button>
      </div> */}
      <div className={styles.searchAndCreate}>
        <input
          type="text"
          placeholder="Search Events"
          className={styles.searchBar}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button className={styles.searchButton} onClick={handleSearch}>
          Search
        </button>
        <button className={styles.createButton} onClick={handleCreateEvent}>
          Create Event
        </button>
      </div>
      <div className={styles.eventList}>
        {filteredEvents.map((event) => (
          <div key={event.event_id} className={styles.eventCard}>
            <div className={styles.eventImage}>
              <img src={event.image} alt={event.event_name} />
            </div>
            <h3>{event.event_name}</h3>
            <p>Start Date: {event.start_at}</p>
            <p>End Date: {event.end_at}</p>
            <p>Cost: ${event.cost}</p>
            <div className={styles.cardButtons}>
              <button
                className={styles.updateButton}
                onClick={() => handleUpdateEvent(event)}
              >
                <img src="/pencil.png" alt="Update" />
              </button>
              <button
                className={styles.joinButton}
                onClick={() => handleJoinEvent(event.events_id)}
              >
                Join
              </button>
              <button
                className={styles.deleteButton}
                onClick={() => handleDeleteEvent(event.events_id)}
              >
                <img src="/trash.png" alt="Delete" />
              </button>
            </div>
          </div>
        ))}
        {/* Dummy event card */}
        {/*<div className={styles.eventCard}>
          <h3>Test Event</h3>
          <p>Start Date: 2023-06-10 10:00:00</p>
          <p>End Date: 2023-06-10 12:00:00</p>
          <p>Cost: $50</p>
          <div className={styles.cardButtons}>
            <button className={styles.updateButton}>
              <img src="/pencil.png" alt="Update" />
            </button>
            <button className={styles.joinButton}>Join</button>
            <button className={styles.deleteButton}>
              <img src="/trash.png" alt="Delete" />
            </button>
          </div>
        </div>*/}
      </div>
      {showForm && (
        <div className={styles.formOverlay}>
          <div className={styles.formContainer}>
            <button className={styles.closeButton} onClick={handleCloseForm}>
              <img src="/close.png" alt="Close" />
            </button>
            <h2>{selectedEvent ? "Update Event" : "Create Event"}</h2>
            {console.log(selectedEvent)}
            <form onSubmit={handleSubmit}>
              <div className={styles.formField}>
                <label htmlFor="eventName">Event Name:</label>
                <input
                  type="text"
                  id="eventName"
                  required
                  defaultValue={selectedEvent ? selectedEvent.event_name : ""}
                />
              </div>
              <div className={styles.formField}>
                <label htmlFor="startAt">Start At:</label>
                <input
                  type="datetime-local"
                  id="startAt"
                  required
                  defaultValue={selectedEvent ? selectedEvent.start_at : ""}
                />
              </div>
              <div className={styles.formField}>
                <label htmlFor="organisationId">Organisation ID:</label>
                <input
                  type="number"
                  id="organisationId"
                  required
                  defaultValue={selectedEvent ? selectedEvent.org_id : ""}
                />
              </div>
              <div className={styles.formField}>
                <label htmlFor="venueId">Venue ID:</label>
                <input
                  type="number"
                  id="venueId"
                  required
                  defaultValue={selectedEvent ? selectedEvent.venue_id : ""}
                />
              </div>
              <div className={styles.formField}>
                <label htmlFor="cost">Cost:</label>
                <input
                  type="number"
                  id="cost"
                  step="0.01"
                  required
                  defaultValue={selectedEvent ? selectedEvent.cost : ""}
                />
              </div>
              <div className={styles.formField}>
                <label htmlFor="description">Description:</label>
                <textarea
                  id="description"
                  required
                  defaultValue={selectedEvent ? selectedEvent.description : ""}
                ></textarea>
              </div>
              <div className={styles.formField}>
                <label htmlFor="endAt">End At:</label>
                <input
                  type="datetime-local"
                  id="endAt"
                  required
                  defaultValue={selectedEvent ? selectedEvent.end_at : ""}
                />
              </div>
              <div className={styles.formField}>
                <label htmlFor="maxParticipants">Max Participants:</label>
                <input
                  type="number"
                  id="maxParticipants"
                  required
                  defaultValue={
                    selectedEvent ? selectedEvent.max_participants : ""
                  }
                />
              </div>
              <div className={styles.formField}>
                <label htmlFor="image">Image:</label>
                <input
                  type="text"
                  id="image"
                  required
                  defaultValue={selectedEvent ? selectedEvent.image : ""}
                />
              </div>
              <div className={styles.formField}>
                <label htmlFor="bookingDate">Booking Date:</label>
                <input
                  type="date"
                  id="bookingDate"
                  required
                  defaultValue={selectedEvent ? selectedEvent.booking_date : ""}
                />
              </div>
              <div className={styles.formField}>
                <label htmlFor="bookingTime">Booking Time:</label>
                <input
                  type="time"
                  id="bookingTime"
                  required
                  defaultValue={selectedEvent ? selectedEvent.booking_time : ""}
                />
              </div>
              <button type="submit" className={styles.submitButton}>
                {selectedEvent ? "Update" : "Create"}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Events;
