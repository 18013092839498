import styles from "./Organizations.module.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const Organizations = () => {
  const [showForm, setShowForm] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async () => {
    try {
      const response = await fetch( process.env.REACT_APP_BACKEND_BASE_URL +  "/org/getAll", {
        headers: {
          Authorization: `${localStorage.getItem("userToken")}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setOrganizations(data.organization);
      } else {
        console.error("Error fetching organizations:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching organizations:", error);
    }
  };

  const handleCreateOrganization = () => {
    setSelectedOrganization(null);
    setShowForm(true);
  };

  const handleUpdateOrganization = (organization) => {
    setSelectedOrganization(organization);
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setSelectedOrganization(null);
    setShowForm(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted");

    const formData = {
      name: e.target.OrganizationName.value,
      location: e.target.OrganizationLocation.value,
      size: parseInt(e.target.size.value),
    };

    console.log("Sending API request with data:", formData);


    try {
      let response;
      if (selectedOrganization) {
        // Update organization
        response = await fetch(process.env.REACT_APP_BACKEND_BASE_URL + `/org/update`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("userToken")}`,
          },
          body: JSON.stringify({ ...formData, org_id: selectedOrganization.org_id }),
        });
      } else {
        // Create new organization
        response = await fetch(process.env.REACT_APP_BACKEND_BASE_URL + `/org/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("userToken")}`,
          },
          body: JSON.stringify(formData),
        });
      }

      if (response.ok) {
        // Handle successful response
        setSelectedOrganization(null);
        setShowForm(false);
        fetchOrganizations(); // Refresh the organization list
      } else {
        console.error("Error submitting form:", response.statusText);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className={styles.Organizations}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <img src="illinois-fighting-illini-logo-1@2x.png" alt="Logo" />
        </div>
        <div className={styles.navList}>
          <div className={styles.sections}>
            <Link to="/home" className={styles.navLink}>Home</Link>
            <Link to="/events" className={styles.navLink}>Events</Link>
            <Link to="/organizations" className={styles.navLink_active}>Organizations</Link>
            <Link to="/venues" className={styles.navLink}>Venues</Link>
            <Link to="/myEvents" className={styles.navLink}>My Events</Link>
            <Link to="/visualization" className={styles.navLink}>Visualization</Link>
          </div>
        </div>
      </div>
      <div className={styles.searchAndCreate}>
        {/* <input type="text" placeholder="Search Organizations" className={styles.searchBar} /> */}
        <button className={styles.createButton} onClick={handleCreateOrganization}>Create Organization</button>
      </div>
      {/* <div className={styles.OrganizationList}>
        Render dummy Organization cards
        <div className={styles.OrganizationCard}>
          <h3>Computer Science</h3>
          <p>Id: 500</p>
          <button className={styles.updateButton} onClick={() => handleUpdateOrganization({ id: 1, name: "Krannert Art Center", capacity: 500 })}>
            <img src="/pencil.png" alt="Update" />
          </button>
        </div>
        <div className={styles.OrganizationCard}>
          <h3>Association for computing machinery</h3>
          <p>Id: 800</p>
          <button className={styles.updateButton} onClick={() => handleUpdateOrganization({ id: 2, name: "Lincoln Hall", capacity: 800 })}>
            <img src="/pencil.png" alt="Update" />
          </button>
        </div>
        Add more Organization cards
      </div> */}
      <div className={styles.OrganizationList}>
  {organizations.map((organization) => (
    <div key={organization.org_id} className={styles.OrganizationCard}>
      <h3>{organization.org_name}</h3>
      <p>Location: {organization.org_location}</p>
      <p>Size: {organization.size}</p>
      <button
        className={styles.updateButton}
        onClick={() => handleUpdateOrganization(organization)}
      >
        <img src="/pencil.png" alt="Update" />
      </button>
    </div>
  ))}
</div>
      {showForm && (
        <div className={styles.formOverlay}>
          <div className={styles.formContainer}>
            <button className={styles.closeButton} onClick={handleCloseForm}>
              <img src="/close.png" alt="Close" />
            </button>
            <h2>{selectedOrganization ? "Update Organization" : "Create Organization"}</h2>
            {/* <form onSubmit={handleSubmit}>
              <div className={styles.formField}>
                <label htmlFor="OrganizationName">Organization Name:</label>
                <input type="text" id="OrganizationName" required />
              </div>
              <div className={styles.formField}>
                <label htmlFor="latitude">Organization Id:</label>
                <input type="number" id="latitude" step="any" />
              </div>
              <div className={styles.formField}>
                <label htmlFor="longitude">Organization Location:</label>
                <input type="number" id="longitude" step="any" />
              </div>
              <div className={styles.formField}>
                <label htmlFor="zipcode">Size:</label>
                <input type="number" id="zipcode" />
              </div>
              
              <button type="submit" className={styles.submitButton}>{selectedOrganization ? "Update" : "Create"}</button>
            </form> */}
            <form onSubmit={handleSubmit}>
              <div className={styles.formField}>
                <label htmlFor="OrganizationName">Organization Name:</label>
                <input type="text" id="OrganizationName" name="OrganizationName" required defaultValue={selectedOrganization ? selectedOrganization.org_name : ""}/>
              </div>
              <div className={styles.formField}>
                <label htmlFor="OrganizationLocation">Organization Location:</label>
                <input type="text" id="OrganizationLocation" name="OrganizationLocation" defaultValue={selectedOrganization ? selectedOrganization.org_location : ""} />
              </div>
              <div className={styles.formField}>
                <label htmlFor="size">Size:</label>
                <input type="number" id="size" name="size" defaultValue={selectedOrganization ? selectedOrganization.size : ""}/>
              </div>

              <button type="submit" className={styles.submitButton}>{selectedOrganization ? "Update" : "Create"}</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Organizations;