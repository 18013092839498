import React from "react";
import axios from "axios";
import styles from "./SignUp.module.css";
import { Link } from "react-router-dom";

const SignUp = () => {
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const userData = {
      first_name: formData.get("firstName"),
      last_name: formData.get("lastName"),
      email: formData.get("email"),
      phone_number: formData.get("phonenumber"),
      department: formData.get("department"),
      campus: formData.get("campus"),
      password: formData.get("password"),
    };

    try {
      const response = await axios.post(
        "https://api-cs411.jugaldb.com/users/signup",
        userData
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error during sign up:", error);
    }
  };

  return (
    <div className={styles.signup_container}>
      <div className={styles.signup_form_container}>
        <div className={styles.left}>
          <h1>Welcome to EventIllini!</h1>
          <Link to="/">
            <button type="button" className={styles.white_btn}>
              Sign in
            </button>
          </Link>
        </div>
        <div className={styles.right}>
          <form className={styles.form_container} onSubmit={handleSubmit}>
            <h1>Create Account</h1>
            <input
              type="text"
              placeholder="First Name"
              name="firstName"
              required
              className={styles.input}
            />
            <input
              type="text"
              placeholder="Last Name"
              name="lastName"
              required
              className={styles.input}
            />
            <input
              type="email"
              placeholder="Email"
              name="email"
              required
              className={styles.input}
            />
            <input
              type="number"
              placeholder="Phone Number"
              name="phonenumber"
              required
              className={styles.input}
            />
            <input
              type="text"
              placeholder="Department"
              name="department"
              required
              className={styles.input}
            />
            <input
              type="text"
              placeholder="Campus"
              name="campus"
              required
              className={styles.input}
            />
            {/* <label for="campus">Campus</label>
            <select name="campus" placeholder="Campus" className={styles.dropDown}>
              <option value="campus" className={styles.dropDown}>Urbana-Champaign</option>
              <option value="campus" className={styles.dropDown}>Chicago</option>
              <option value="campus" className={styles.dropDown}>Springfield</option>
            </select> */}
            <input
              type="password"
              placeholder="Password"
              name="password"
              required
              className={styles.input}
            />
            <button type="submit" className={styles.green_btn}>
              Sign Up
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
