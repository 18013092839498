// Home.js
import styles from "./Home.module.css";
import { Link } from "react-router-dom";

const Home = () => {
  const userFirstName = localStorage.getItem('userFirstName');
  const userLastName = localStorage.getItem('userLastName');
  const userId = localStorage.getItem('userId');

  return (
    <div className={styles.home}>
      <div className={styles.wrapper}>
        <div className={styles.container} />
        <div className={styles.imagePlaceholder}>
          <div className={styles.imageContainer} />
        </div>
        <div className={styles.header2}> 
          <div className={styles.header2Inner}>
            <div className={styles.groupChild} />
          </div>
          <div className={styles.header}>
        <div className={styles.logo}>
          <img src="illinois-fighting-illini-logo-1@2x.png" alt="Logo" />
        </div>
        <div className={styles.navList}>
          <div className={styles.sections}>
            <Link to="/home" className={styles.navLink_active}>Home</Link>
            <Link to="/events" className={styles.navLink}>Events</Link>
            <Link to="/organizations" className={styles.navLink}>Organizations</Link>
            <Link to="/venues" className={styles.navLink}>Venues</Link>
            <Link to="/myEvents" className={styles.navLink}>My Events</Link>
            <Link to="/visualization" className={styles.navLink}>Visualization</Link>

            {userId}
          </div>
        </div>
      </div>
        </div>
        <b className={styles.heading}>
          <p className={styles.connectingCommunities}>
          Welcome, {userFirstName} {userLastName}! Connecting Communities,
          </p>
          <p
            className={styles.connectingCommunities}
          >{`Tailoring Experiences `}</p>
        </b>
        <div className={styles.paragraph}>
          Navigate the dynamic world of UIUC events effortlessly with our app
          simplifying schedules
        </div>
        <div className={styles.buttonSet} />
      </div>
      <img
        className={styles.illinoisFightingIlliniLogoIcon}
        alt=""
        src="/illinois-fighting-illini-logo-1@2x.png"
      />
      <img className={styles.cupIcon} alt="" src="/cup@2x.png" />
      <img className={styles.ideaLampIcon} alt="" src="/idea-lamp@2x.png" />
      <img className={styles.pencilCupIcon} alt="" src="/pencil-cup@2x.png" />
      <img
        className={styles.noteAndPencil}
        alt=""
        src="/note-and-pencil@2x.png"
      />
    </div>
  );
};

export default Home;