import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Login.module.css";

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_BASE_URL + '/users/login', {
        email: email,
        password: password
      });
      // Assuming the response includes a success status or token
      localStorage.setItem('userFirstName', response.data.userDetails.first_name);
      localStorage.setItem('userLastName', response.data.userDetails.last_name);
      localStorage.setItem('userId', response.data.userDetails.user_id);
      localStorage.setItem('userToken', response.data.token);
      console.log(response);
      console.log(response.data);
      // Redirect to home page or other appropriate page
      navigate('/home');
    } catch (error) {
      console.error("Login failed:", error);
      // Handle errors here, such as displaying a notification
    }
  };

  return (
    <div className={styles.login_container}>
      <div className={styles.login_form_container}>
        <div className={styles.left}>
          <form className={styles.form_container} onSubmit={handleSubmit}>
            <h1>Welcome to EventIllini</h1>
            <input
              type="email"
              placeholder="Email"
              name="email"
              required
              className={styles.input}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              name="password"
              required
              className={styles.input}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button type="submit" className={styles.green_btn}>
              Sign In
            </button>
          </form>
        </div>
        <div className={styles.right}>
          <h1>New Here ?</h1>
          <Link to="/signup">
            <button type="button" className={styles.white_btn}>
              Sign Up
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
