// Venues.js
import styles from "./Venues.module.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

const Venues = () => {
  const [showForm, setShowForm] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [venues, setVenues] = useState([]);

  useEffect(() => {
    fetchVenues();
  }, []);

  const fetchVenues = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_BASE_URL + "/venues/getAll", {
        headers: {
          Authorization: `${localStorage.getItem("userToken")}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setVenues(data.venue);
        console.log(data);
      }
      else {
        console.error("Error fetching venues:", response.statusText);
      }
    }
    catch (error) {
      console.error("Error fetching venues:", error);
    }
  }
  const formatDateTime = (dateTimeValue) => {
    if (dateTimeValue) {
      const [date, time] = dateTimeValue.split("T");
      return `${date} ${time}:00`;
    }
    return null;
  };

  const extractTime = (dateTimeString) => {
    if (dateTimeString) {
      const time = new Date(dateTimeString).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      });
      return time;
    }
    return "N/A"; // Return "Not Available" if the string is undefined or empty
  };

  const handleCreateVenue = () => {
    setSelectedVenue(null);
    setShowForm(true);
  };

  const handleUpdateVenue = (venue) => {
    setSelectedVenue(venue);
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setSelectedVenue(null);
    setShowForm(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      venue_name: e.target.venueName.value,
      latitude: parseFloat(e.target.latitude.value),
      longitude: parseFloat(e.target.longitude.value),
      zipcode: parseInt(e.target.zipcode.value),
      capacity: parseInt(e.target.capacity.value),
      opening_time: formatDateTime(e.target.openingTime.value),
      closing_time: formatDateTime(e.target.closingTime.value),
      address_line1: e.target.address1.value,
      address_line2: e.target.address2.value,
      city: e.target.city.value,
      state: e.target.state.value,
      country: e.target.country.value,
    };
    console.log(formData);

    try {
      let response;
      if (selectedVenue) {
        // Update venue
        response = await fetch(process.env.REACT_APP_BACKEND_BASE_URL + `/venues/update`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("userToken")}`,
          },
          body: JSON.stringify({ ...formData, venue_id: selectedVenue.venue_id }),
        });
      } else {
        // Create new venue
        response = await fetch(process.env.REACT_APP_BACKEND_BASE_URL + `/venues/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("userToken")}`,
          },
          body: JSON.stringify(formData),
        });
      }

      if (response.ok) {
        setSelectedVenue(null);
        setShowForm(false);
        fetchVenues();
      } else {
        console.error("Error submitting form:", response.statusText);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className={styles.venues}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <img src="illinois-fighting-illini-logo-1@2x.png" alt="Logo" />
        </div>
        <div className={styles.navList}>
          <div className={styles.sections}>
            <Link to="/home" className={styles.navLink}>Home</Link>
            <Link to="/events" className={styles.navLink}>Events</Link>
            <Link to="/organizations" className={styles.navLink}>Organizations</Link>
            <Link to="/venues" className={styles.navLink_active}>Venues</Link>
            <Link to="/myEvents" className={styles.navLink}>My Events</Link>
            <Link to="/visualization" className={styles.navLink}>Visualization</Link>
          </div>
        </div>
      </div>
      <div className={styles.searchAndCreate}>
        <button className={styles.createButton} onClick={handleCreateVenue}>Create Venue</button>
      </div>
      {/* <div className={styles.venueList}>
        Render dummy venue cards
        <div className={styles.venueCard}>
          <h3>Krannert Art Center</h3>
          <p>Capacity: 500</p>
          <button className={styles.updateButton} onClick={() => handleUpdateVenue({ id: 1, name: "Krannert Art Center", capacity: 500 })}>
            <img src="/pencil.png" alt="Update" />
          </button>
        </div>
        <div className={styles.venueCard}>
          <h3>Lincoln Hall</h3>
          <p>Capacity: 800</p>
          <button className={styles.updateButton} onClick={() => handleUpdateVenue({ id: 2, name: "Lincoln Hall", capacity: 800 })}>
            <img src="/pencil.png" alt="Update" />
          </button>
        </div>
        Add more venue cards
      </div> */}
      <div className={styles.venueList}>
        {venues.map((venue) => (
          <div key={venue.venue_id} className={styles.venueCard}>
            <h3>{venue.venue_name}</h3>
            <p>Opening Time: {extractTime(venue.opening_time)}</p>
            <p>Closing Time: {extractTime(venue.closing_time)}</p>
            <p>Address: {venue.address_line1}</p>
            <p>City: {venue.city}</p>
            <p>State: {venue.state}</p>
            <p>Country: {venue.country}</p>
            <button className={styles.updateButton}
              onClick={() => handleUpdateVenue(venue)}>
              <img src="/pencil.png" alt="Update" />
            </button>
          </div>
        ))}
      </div>
      {showForm && (
        <div className={styles.formOverlay}>
          <div className={styles.formContainer}>
            <button className={styles.closeButton} onClick={handleCloseForm}>
              <img src="/close.png" alt="Close" />
            </button>
            <h2>{selectedVenue ? "Update Venue" : "Create Venue"}</h2>
            <form onSubmit={handleSubmit}>
              <div className={styles.formField}>
                <label htmlFor="venueName">Venue Name:</label>
                <input type="text" id="venueName" required defaultValue={selectedVenue ? selectedVenue.venue_name : "" }/>
              </div>
              <div className={styles.formField}>
                <label htmlFor="latitude">Latitude:</label>
                <input type="number" id="latitude" step="any" defaultValue={selectedVenue ? selectedVenue.latitude : "" }/>
              </div>
              <div className={styles.formField}>
                <label htmlFor="longitude">Longitude:</label>
                <input type="number" id="longitude" step="any" defaultValue={selectedVenue ? selectedVenue.longitude : "" }/>
              </div>
              <div className={styles.formField}>
                <label htmlFor="zipcode">Zipcode:</label>
                <input type="number" id="zipcode" defaultValue={selectedVenue ? selectedVenue.zipcode : "" }/>
              </div>
              <div className={styles.formField}>
                <label htmlFor="capacity">Capacity:</label>
                <input type="number" id="capacity" defaultValue={selectedVenue ? selectedVenue.capacity : "" }/>
              </div>
              <div className={styles.formField}>
                <label htmlFor="openingTime">Opening Time:</label>
                <input type="datetime-local" id="openingTime" required defaultValue={selectedVenue ? selectedVenue.opening_time : "" }/>
              </div>
              <div className={styles.formField}>
                <label htmlFor="closingTime">Closing Time:</label>
                <input type="datetime-local" id="closingTime" defaultValue={selectedVenue ? selectedVenue.closing_time : "" }/>
              </div>
              <div className={styles.formField}>
                <label htmlFor="address1">Address:</label>
                <input type="text" id="address1" defaultValue={selectedVenue ? selectedVenue.address_line1 : "" }/>
              </div>
              <div className={styles.formField}>
                <label htmlFor="address2">Address:</label>
                <input type="text" id="address2" defaultValue={selectedVenue ? selectedVenue.address_line2 : "" }/>
              </div>
              <div className={styles.formField}>
                <label htmlFor="city">City:</label>
                <input type="text" id="city" defaultValue={selectedVenue ? selectedVenue.city : "" }/>
              </div>
              <div className={styles.formField}>
                <label htmlFor="state">State:</label>
                <input type="text" id="state" defaultValue={selectedVenue ? selectedVenue.state : "" }/>
              </div>
              <div className={styles.formField}>
                <label htmlFor="country">Country:</label>
                <input type="text" id="country" defaultValue={selectedVenue ? selectedVenue.country : "" }/>
              </div>

              <button type="submit" className={styles.submitButton}>{selectedVenue ? "Update" : "Create"}</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Venues;